import Head from 'next/head';
import type { FunctionComponent } from 'react';
import { translate } from '@/domains/core/localization/translate';
import { getLocale, getPlatform } from '@/domains/core/settings/utils';
import { MetaTagsMarket } from './MetaTagsMarket/MetaTagsMarket.default';
export const {
  metaDescription
} = translate({
  "metaDescription": "tous vos produits de bricolage, r\xE9novation et jardinage au meilleur prix"
});
export const MetaTags: FunctionComponent<MetaTagsProps> = ({
  canonicalUrl,
  pageDescription = `ManoMano : ${metaDescription}`,
  pageName,
  pageTitle = pageName,
  pageType,
  robotsContent
}) => <>
    <Head>
      <title key="meta_title">{pageTitle}</title>
      {robotsContent && <meta key="meta_robots" name="robots" content={robotsContent} />}
      <meta key="meta_description" name="description" content={pageDescription} />
      <meta key="meta_page_type" name="page-type" content={pageType}
    // TODO remove the 3 attributes below when no more usage (tests and crawlers)
    data-locale={getLocale()} data-page-name={pageName} data-platform={getPlatform()} />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl.toString()} key="canonical" />}
    </Head>
    <MetaTagsMarket />
  </>;
export interface MetaTagsProps {
  canonicalUrl?: URL | string;
  pageDescription?: string;
  pageName: string;
  pageTitle?: string;
  pageType: string;
  robotsContent?: string;
}